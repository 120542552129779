<template>
  <div>
    <Support />
    <CommonUnsupportedBrowserModal v-if="supportedBrowser.showModal" />
    <slot />
  </div>
</template>
<script setup lang="ts">
import { useSupportedBrowserStore } from "~/store/supportedBrowser";

useHead({
  htmlAttrs: {
    lang: "en",
  },
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no",
    },
  ],
});
const supportedBrowser = useSupportedBrowserStore();
useRefreshAuthTokens();
</script>
<style lang="scss" scoped>
body {
  margin: 0;
}
</style>
