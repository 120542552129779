import { useAuthStore } from "~/store/auth";

export const useRefreshAuthTokens = () => {
  const authStore = useAuthStore();

  useIntervalFn(async () => {
    const accessToken = authStore.getCookieAccessToken();
    const refreshToken = authStore.getCookieRefreshToken();
    if (!accessToken && refreshToken) {
      await authStore.refreshAuthTokens();
    }
  }, 60000);
};
